<template>


    <div class="row" v-bind:class="{'is-loading':loading}">
        <div class="col-sm-8">

            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-lg">

                            <h3>Nowe rozliczenie</h3>
                        </div>
                        <div class="col-lg-auto">
                            <!--                            <button type="button"-->
                            <!--                                    class="btn btn-outline-primary"-->
                            <!--                                    v-on:click="autoFill()">-->
                            <!--                                Uzupełnij automatycznie-->
                            <!--                            </button>-->
                            <button type="button"
                                    class="btn btn-outline-primary"
                                    v-on:click="autoFill2()">
                                Uzupełnij automatycznie
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group row"><label
                        class="col-sm-12 col-form-label">Użytkownik:</label>
                        <div class="col-sm-auto">
                            <a :href="`/admin/user/${user.id}/edit`"
                               class="btn btn-outline-secondary">{{ user.name }}</a>
                            <br>
                            <span v-if="user.comments">
							Komentarz: {{ user.comments }}
							</span>
                        </div>
                        <div class="col-sm">
                            <table class="table table-hover table-sm">
                                <thead>
                                <tr>
                                    <th>Wypłaty</th>
                                    <template v-if="user.contribution">
                                        <th>Składki</th>
                                        <th>Podstawa do ZUS</th>
                                        <th>Koszt umowa zlecenie</th>
                                        <th>K.OBS.UZ</th>
                                        <th>Wysyłamy</th>
                                    </template>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <span v-if="user.withdrawals === 'daily'">Codzienne</span>
                                        <span
                                            v-if="user.withdrawals === 'weekly'">Co okres rozliczeniowy aplikacji</span>
                                        <span v-if="user.withdrawals === 'per-request'">Na zlecenie</span>
                                    </td>
                                    <template v-if="user.contribution">
                                        <td>{{ user.contribution.name }}</td>
                                        <td>{{ user.base_for_zus }}</td>
                                        <td>{{ user.zus_cost }}</td>
                                        <td>{{ user.zus_cost_z }}</td>
                                        <td>{{ user.zus_send }}</td>
                                    </template>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <input type="hidden"
                           name="user_id"
                           :value="`${user.id}`"/>
                    <div class="form-group row">
                        <div class="col-sm-12 col-form-label"><strong>Salda</strong></div>
                        <div class="col-sm">
                            <table class="table table-hover table-sm">
                                <thead>
                                <tr>
                                    <td></td>
                                    <td>Saldo (zł)</td>
                                    <td>Gotówka (zł)</td>
                                    <td>Obrót (zł)</td>
                                    <td>Utworzone</td>
                                    <td>Aplikacja</td>
                                    <td>Prowizja</td>
                                    <td>Podatki</td>
                                    <td>Status</td>
                                    <td>ZUS pobrany</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="balance in balances"
                                    v-on:click="toggleBalance(balance)">
                                    <td>
                                        <input type="checkbox"
                                               v-model="selectedBalances"
                                               :value="balance"/>
                                        {{ balance.id }}
                                    </td>
                                    <td>{{ balance.online }}</td>
                                    <td>{{ balance.collected_cash }}</td>
                                    <td>{{ balance.total }}</td>
                                    <td>{{ formatDate(balance.created_at) }}</td>
                                    <td>{{ balance.application.name }}</td>
                                    <td>{{ balance.provision }} zł</td>
                                    <td>
                                        <template v-if="balance.imported && balance.imported.length>0">
                                            <strong>
                                                <span
                                                    v-for="vat in balance.imported">[{{ vat.vat }}% {{
                                                        vat.value
                                                    }}zł]</span>
                                            </strong>
                                        </template>
                                        <template v-else>
                                            <input type="radio"
                                                   :id="`vat0-${balance.id}`"
                                                   :name="`vat-${balance.id}`"
                                                   v-model="balance.vat"
                                                   value="0"
                                                   class="part-vat"
                                                   v-on:change="calculatePayment()"
                                                   checked/>
                                            <label :for="`vat0-${balance.id}`"
                                                   class="mx-2 my-0">0%</label>
                                            <input type="radio"
                                                   :id="`vat8-${balance.id}`"
                                                   v-on:change="calculatePayment()"
                                                   :name="`vat-${balance.id}`"
                                                   v-model="balance.vat"
                                                   value="8.1"
                                                   class="part-vat"/>
                                            <label :for="`vat8-${balance.id}`"
                                                   class="mx-2 my-0">8%</label>
                                            <input type="radio"
                                                   :id="`vat23-${balance.id}`"
                                                   v-on:change="calculatePayment()"
                                                   :name="`vat-${balance.id}`"
                                                   v-model="balance.vat"
                                                   value="18.7"
                                                   class="part-vat"/>
                                            <label :for="`vat23-${balance.id}`"
                                                   class="mx-2 my-0">23%</label>
                                        </template>
                                    </td>
                                    <td>
                                        <span v-if="balance.status">Aktywne</span>
                                        <span v-if="!balance.status">Nieaktywne</span>
                                    </td>
                                    <td>
                                        <span v-if="balance.zus_taken">Tak</span>
                                        <span v-if="!balance.zus_taken">Nie</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="form-group row">

                        <div class="col-12">
                            <additional-fields-component :additional-fields="additionalFields"
                                                         :title="'Pola dodatkowe'"
                                                         :key="componentKey"
                                                         :show-repeat="false"
                                                         @fields-changed="additionalFieldsChanged"
                                                         :fields="paymentAdditionalFields"></additional-fields-component>
                        </div>


                    </div>
                    <div class="row"
                         v-if="user.referral_ask>0">
                        <div class="col-lg-12">
                            Użytkownik prosi o wypłatę bonusu:
                            <strong>{{ user.referral_ask }}zł</strong>
                            <label>
                                <input type="checkbox"
                                       v-on:change="referralCbChanged()"
                                       v-model="referral_cb"
                                       :value="+user.referral_ask"
                                       name="referral_ask"/>
                                Zaakceptuj
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-2">
                            Możliwy zwrot z faktur:
                        </div>
                        <div class="col-lg">
                            VAT: <strong>{{ invoices_vat_return }}zł</strong>
                        </div>
                        <div class="col-lg">
                            Koszty: <strong>{{ invoices_cost_return }}zł</strong>
                        </div>
                        <div class="col-lg-auto">
                            <button type="button"
                                    class="btn btn-outline-primary"
                                    v-on:click="useReturns()">
                                Użyj w rozliczeniu
                            </button>
                        </div>
                    </div>

                    <div class="form-group row">

                        <label for="vat2"
                               class="col-sm-2 col-form-label">
                            Wpisz kwotę zwrotu VAT 50%
                        </label>
                        <div class="col-sm-10">
                            <input type="text"
                                   name="vat2"
                                   v-model="vat2"
                                   v-on:change="calculatePayment()"
                                   id="vat2"
                                   class="form-control"
                                   required="required"
                                   placeholder="Uwaga! Możesz zredukować podatek tylko do kwoty jego wysokości">

                        </div>

                    </div>

                    <div class="form-group row align-items-center">
                        <label for="provision"
                               class="col-sm-2 col-form-label">Prowizja:</label>
                        <div class="col-sm">
                            <input name="provisionManual"
                                   type="number"
                                   v-on:change="calculatePayment()"
                                   v-model="provision"
                                   id="provision"
                                   class="form-control">
                            <input name="provision"
                                   type="hidden"
                                   v-model="provisionTotal">
                        </div>
                        <div class="col-sm-auto">
                            <div class="btn-group">
                                <button class="btn btn-outline-primary"
                                        type="button"
                                        v-on:click="setProvision(0)">0zł
                                </button>
                                <button class="btn btn-outline-primary"
                                        type="button"
                                        v-on:click="setProvision(20)">
                                    20zł
                                </button>
                                <button class="btn btn-outline-primary"
                                        type="button"
                                        v-on:click="setProvision(30)">
                                    30zł
                                </button>
                                <button class="btn btn-outline-primary"
                                        type="button"
                                        v-on:click="setProvision(50)">
                                    50zł
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            Z sald: <strong>{{ provisionFromBalances }} zł</strong>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="elixir"
                               class="col-sm-2 col-form-label">Przelew:</label>
                        <div class="col-sm-10">
                            <select id="elixir"
                                    name="elixir"
                                    required
                                    v-on:change="calculatePayment()"
                                    v-model="elixir"
                                    class="form-control w-100">
                                <option value="0"
                                        selected="selected">Zwykły
                                </option>
                                <option value="1">Elixir</option>
                            </select></div>
                    </div>

                    <div class="form-group row">
                        <label for="firma"
                               class="col-sm-2 col-form-label">Firma:</label>
                        <div class="col-sm-10">
                            <select id="firma"
                                    name="firma"
                                    required
                                    v-on:change="calculatePayment()"
                                    v-model="firma"
                                    class="form-control w-100">
                                <option value="0"
                                        selected="selected">Nie
                                </option>
                                <option value="1">Tak</option>
                            </select>
                        </div>
                    </div>

                    <!--                    <div class="form-group row d-none">-->
                    <!--                        <label for="cost_uz_dochodowy"-->
                    <!--                               class="col-sm-2 col-form-label">Koszt umowa zlecenie:</label>-->
                    <!--                        <div class="col-sm-10">-->
                    <input name="cost_uz_dochodowy"
                           type="hidden"
                           v-on:change="calculatePayment()"
                           v-model="cost_uz_dochodowy"
                           id="cost_uz_dochodowy"
                           class="form-control">
                    <!--                </div>-->
                    <!--                    </div>-->

                    <template v-if="firma===1">
                        <div class="form-group row">
                            <label for="wyplata_b2b"
                                   class="col-sm-2 col-form-label">B2B:</label>
                            <div class="col-sm-10">
                                <input name="wyplata_b2b"
                                       v-model="kwota_z_wyplat"
                                       type="number"
                                       step="0.01"
                                       readonly
                                       id="wyplata_b2b"
                                       class="form-control"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form-group row align-items-center">
                            <label for="wyplata_umowa_zlecenie"
                                   class="col-sm-2 col-form-label">Umowa zlecenie:</label>
                            <div class="col-sm">
                                <input name="wyplata_umowa_zlecenie"
                                       v-model="wyplata_umowa_zlecenie"
                                       type="number"
                                       step="0.01"
                                       v-on:change="calculatePayment()"
                                       id="wyplata_umowa_zlecenie"
                                       class="form-control">
                            </div>
                            <div class="col-sm-auto"
                                 v-if="user.contribution">
                                <div class="btn-group">
                                    <button class="btn btn-outline-primary"
                                            type="button"
                                            v-on:click="calculateZus()">
                                        Koszty ZUS
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-auto">
                                <label>
                                    <input type="checkbox"
                                           v-model="zusIncluded"
                                           name="zus-included"/>
                                    ZUS uwzględniony
                                </label>
                            </div>
                            <div class="col-sm-auto">
                                <small>Ostatnio pobrane:</small><br>
                                {{ user.contribution_used_at ? user.contribution_used_at : 'Nie pobrano' }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="wyplata_umowa_wynajmu"
                                   class="col-sm-2 col-form-label">Umowa wynajmu:</label>
                            <div class="col-sm-10">
                                <input name="wyplata_umowa_wynajmu"
                                       v-model="wyplata_umowa_wynajmu"
                                       type="number"
                                       step="0.01"
                                       readonly
                                       id="wyplata_umowa_wynajmu"
                                       class="form-control"></div>
                        </div>
                        <div class="form-group row">
                            <label for="wyplata_zwrot_kosztow"
                                   class="col-sm-2 col-form-label">Zwrot kosztów:</label>
                            <div class="col-sm-10">
                                <input name="wyplata_zwrot_kosztow"
                                       type="number"
                                       step="0.01"
                                       v-on:change="calculatePayment()"
                                       v-model="wyplata_zwrot_kosztow"
                                       id="wyplata_zwrot_kosztow"
                                       class="form-control"></div>
                        </div>
                        <div class="form-group row">
                            <label for="wyplata_zwrot_kosztow"
                                   class="col-sm-2 col-form-label">Umowa serwisowa:</label>
                            <div class="col-sm-10">
                                <input name="wyplata_umowa_serwisowa"
                                       type="number"
                                       step="0.01"
                                       v-on:change="calculatePayment()"
                                       v-model="wyplata_umowa_serwisowa"
                                       id="wyplata_umowa_serwisowa"
                                       class="form-control"></div>
                        </div>
                    </template>

                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="card sticky-top">
                <div class="card-header">
                    <h3>Wyliczenia</h3>
                </div>
                <div class="card-body">

                    <table>
                        <tbody>
                        <tr>
                            <th>Suma brutto:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(calculated.brutto) }}</strong></td>
                        </tr>
                        <tr>
                            <th>Suma płatności online:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(calculated.onlinePayment) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <th>VAT należny:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(calculated.totalVat) }}</strong></td>
                        </tr>
                        <tr>
                            <th v-if="!firma">Umowa wynajmu:</th>
                            <th v-if="firma">B2B:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(+kwota_z_wyplat) }}</strong></td>
                        </tr>
                        <tr>
                            <th>Umowa serwisowa:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(+wyplata_umowa_serwisowa) }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <th>Pobrana gotówka:</th>
                            <td class="text-right"><strong>{{ $filters.toCurrency(calculated.cash) }}</strong></td>
                        </tr>
                        <tr>
                            <th>Wypłata bonusu:</th>
                            <td class="text-right"><strong>{{
                                    $filters.toCurrency(referral_cb ? +user.referral_ask : 0)
                                }}</strong></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="alert alert-info">
                            <th class="p-2">
                                Wypłata
                            </th>
                            <td class="text-right p-2"><strong>{{ $filters.toCurrency(total_payment) }}</strong></td>
                        </tr>
                        </tfoot>
                    </table>

                    <div class="alert alert-danger mt-2"
                         v-if="total_payment<50">Mała kwota wypłaty
                    </div>
                    <div class="alert alert-danger mt-2"
                         v-if="total_payment<wyplata_umowa_zlecenie">Za mala wypłata w stosunku do umowy zlecenie
                    </div>

                    <input type="hidden"
                           v-model="calculated.cash"
                           name="cash"/>
                    <input type="hidden"
                           v-model="calculated.brutto"
                           name="brutto"/>
                    <input type="hidden"
                           v-model="calculated.onlinePayment"
                           name="amount"/>
                    <input type="hidden"
                           v-model="calculated.totalVat"
                           name="vat"/>
                    <input type="hidden"
                           v-model="kwota_z_wyplat"
                           name="wyplata_kwota_z_wyplat_value"/>
                    <input type="hidden"
                           v-model="wyplata_umowa_serwisowa"
                           name="service"/>
                    <input type="hidden"
                           v-model="zwrot_vat"
                           name="zwrot_vat"/>
                    <input type="hidden"
                           v-model="total_payment"
                           name="saldo"/>
                    <input name="provisionFromBalances"
                           type="hidden"
                           v-model="provisionFromBalances">
                    <input name="taxiProvision"
                           type="hidden"
                           v-model="taxiProvision">
                    <input name="courierProvision"
                           type="hidden"
                           v-model="courierProvision">
                    <input type="hidden"
                           :value="transformBalances()"
                           name="selected_balances"/>

                </div>
            </div>
        </div>
        <div class="col-12">
            <input type="submit"
                   :disabled="submitDisabled"
                   class="btn btn-lg btn-success mb-3"
                   name="submit"
                   value="Prześlij"/>
            <input type="submit"
                   :disabled="submitDisabled"
                   name="submit"
                   class="btn btn-lg btn-success mb-3"
                   value="Prześlij i dodaj nowe"/>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import * as _ from 'lodash';
import AdditionalFields from "./AdditionalFields.vue";


export default {
    name: 'Payment',
    components: {AdditionalFields},
    data: function () {
        return {
            componentKey: 0,
            invoices_vat_return: 0,
            loading: false,
            invoices_cost_return: 0,
            paymentAdditionalFields: [],
            calculated: {
                brutto: 0,
                onlinePayment: 0,
                totalVat: 0,
                cash: 0
            },
            firma: 0,
            total_payment: 0,
            zwrot_vat: 0,
            kwota_z_wyplat: 0,
            selectedBalances: [],
            provision: 0,
            provisionTotal: 0,
            provisionFromBalances: 0,
            taxiProvision: 0,
            courierProvision: 0,
            elixir: 0,
            vat2: 0,
            referral_ask: null,
            referral_cb: null,
            cost_uz_dochodowy: 0,
            wyplata_umowa_zlecenie: null,
            wyplata_umowa_wynajmu: null,
            wyplata_zwrot_kosztow: null,
            wyplata_umowa_serwisowa: 0,
            submitDisabled: true,
            zusIncluded: false,
        }
    },
    props: {
        balances: {
            type: Array,
            required: true
        },
        additionalFields: {
            type: Array,
            required: true
        },
        provisionSettings: {
            type: Object,
            required: true
        },
        provisionMultiplier: {
            type: Number
        },
        calculateUrl: {
            type: String,
            required: true
        },
        user: {
            type: Object
        }
    },
    watch: {
        paymentAdditionalFields: {
            handler: function () {
                this.calculatePayment();
            },
            deep: true
        },
        selectedBalances: function () {
            this.calculatePayment();
        },
    },
    mounted() {
        document.addEventListener('onFormSubmit', this.handleFormSubmit);
    },
    beforeUnmount() {
        document.removeEventListener('onFormSubmit', this.handleFormSubmit);
    },
    methods: {
        handleFormSubmit() {
            // Perform the necessary actions here
            this.blockSubmit();
        },
        sortArrays(arrays) {
            return _.orderBy(arrays, 'created_at', 'desc');
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        isNumeric: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        additionalFieldsChanged(fields) {
            console.log('1', fields);
            this.paymentAdditionalFields = fields;
            this.calculatePayment();
        },
        transformBalances() {
            console.log(this.selectedBalances);
            if (!this.selectedBalances) {
                return '';
            }
            return this.selectedBalances.map(function (item) {
                console.log(item);
                return item.id;
            }).join(",");
        },
        blockSubmit() {
            this.submitDisabled = true;
        },
        setProvision(value) {
            this.provision = value;
            this.calculatePayment();
        },
        useReturns() {
            this.vat2 = (this.invoices_vat_return < this.calculated.totalVat ? this.invoices_vat_return : this.calculated.totalVat);
            this.calculatePayment();
            if (this.user.firm_student === 1 || this.user.only_rent === 1) {
                this.wyplata_zwrot_kosztow = 0;
                return;
            }
            if (this.user.firm_vat === 1) {
                this.vat2 = this.calculated.totalVat;
                this.calculatePayment();
                return;
            }
            this.wyplata_zwrot_kosztow = Math.min(this.wyplata_umowa_wynajmu, this.invoices_cost_return);
            this.calculatePayment();
        },
        getZusTotalCosts() {
            if (this.user.only_rent === 1 || this.user.firm_student === 1) {
                return 0;
            }
            return +this.user.zus_cost_z + (+this.user.zus_cost);
        },
        calculateZus() {
            if (this.user.only_rent === 1) {
                return;
            }
            if (this.user && this.user.contribution && this.user.zus_cost_z) {
                this.wyplata_umowa_zlecenie += +this.user.zus_send;
                this.paymentAdditionalFields.push({
                    name: `Koszt umowa zlecenie`,
                    value: -this.user.zus_cost
                });
                this.paymentAdditionalFields.push({name: `K.OBS.UZ`, value: -this.user.zus_cost_z});
                this.zusIncluded = true;
            }

            this.calculatePayment();
        },
        init() {
            this.invoices_vat_return = +this.user.invoices_vat_balance;// Math.round(100 * ((+this.user.invoices_vat_return) - (+this.user.used_invoice_return))) / 100;
            this.invoices_cost_return = +this.user.invoices_cost_balance;// Math.round(100 * ((+this.user.invoices_cost_return) - (+this.user.used_cost_return))) / 100;
            _.forEach(this.user.additional_fields, (item) => {
                item.additional_field = _.find(this.additionalFields, (field) => item.additional_field_id === field.id);
            });
        },
        autoFill2() {
            this.loading = true;
            if (this.user.firm_no_vat || this.user.firm_vat) {
                this.firma = 1;
            }
            axios
                .get(this.calculateUrl)
                .then(response => {
                    this.fillFields(response.data);
                })
                .finally(() => this.loading = false);
        },
        fillFields(data) {
            if (data.selected_balances.length === 0) {
                alert('Brak sald do rozliczenia!');
                return;
            }
            _.forEach(data.selected_balances.split(','), (item) => {
                this.toggleBalance(_.find(this.balances, (item2) => item2.id === +item));
            });
            this.paymentAdditionalFields = [];
            for (let field of data.additional_fields) {
                field.additional_field = _.find(this.additionalFields, (item) => field.additional_field_id === item.id);
                this.paymentAdditionalFields.push(field);
            }
            if (data.hasReferral) {
                this.referral_cb = true;
            }
            this.zusIncluded = data.zus_included;
            this.wyplata_umowa_serwisowa = data.wyplata_umowa_serwisowa;
            this.wyplata_umowa_wynajmu = data.wyplata_umowa_wynajmu;
            this.wyplata_umowa_zlecenie = data.wyplata_umowa_zlecenie;
            this.wyplata_zwrot_kosztow = data.wyplata_zwrot_kosztow;
            this.vat2 = data.zwrot_vat;
            this.total_payment = data.total_payment;
            this.provisionFromBalances = data.provisionFromBalances;
            this.taxiProvision = data.taxiProvision;
            this.courierProvision = data.courierProvision;
            this.provision = data.provision;
            this.provisionTotal = data.provisionTotal;
            this.kwota_z_wyplat = data.kwota_z_wyplat;
            this.calculated.cash = data.calculated.cash;
            this.calculated.brutto = data.calculated.brutto;
            this.calculated.onlinePayment = data.calculated.onlinePayment;
            this.calculated.totalVat = data.calculated.totalVat;
            if (!data.canMakePaymentInTotal) {
                alert('Za mała wypłata!');
            }
            if (!data.can_make_payment_with_additional) {
                alert('Za mała wypłata żeby uwzględnić pola tymczasowe!');
            }
            this.componentKey += 1;
        },
        autoFill() {
            this.paymentAdditionalFields = [];
            this.selectedBalances = _.cloneDeep(this.balances);
            console.log(this.selectedBalances);
            this.wyplata_umowa_zlecenie = 0;
            const appProvisions = {
                taxi: [],
                courier: []
            };
            let type_of_withdrawals = `provision_${this.user.withdrawals}`;
            if (this.user.withdrawals === 'per-request') {
                type_of_withdrawals = 'provision_per_request';
            }

            for (const balance of this.selectedBalances) {
                appProvisions[balance.application.type] = _.unionWith(appProvisions[balance.application.type], [balance.application], _.isEqual);
            }
            let provision = 0;
            switch (+this.user.provision_type) {
                case 0:
                    for (const key in appProvisions) {
                        const noOfApps = _.size(appProvisions[key]);
                        if (key === 'taxi' && noOfApps > 0) {
                            provision += this.provisionSettings[this.user.withdrawals][key];
                        }
                        if (key === 'courier') {
                            for (const app of appProvisions[key]) {
                                provision += app[type_of_withdrawals] ? +app[type_of_withdrawals] : this.provisionSettings[this.user.withdrawals][key];
                            }
                        }
                    }
                    break;
                case 1:
                    provision = 0;
                    break;
            }
            if (this.user.firm_no_vat || this.user.firm_vat) {
                this.firma = 1;
            }
            this.calculatePayment();
            if (this.user.should_add_contribution_to_payment) {
                if (this.getZusTotalCosts() * 1.2 < this.total_payment) {
                    this.calculateZus();
                } else {
                    alert('Za mała wypłata żeby uwzględnić ZUS!');
                }
            }
            if (+this.user.additional_fields_balance + this.total_payment < 0) {
                alert('Za mała wypłata żeby uwzględnić pola tymczasowe!');
            }

            for (let field of this.user.additional_fields) {
                this.paymentAdditionalFields.push(field);
            }
            this.calculatePayment();
            this.provision = provision;
            this.calculatePayment();
            this.useReturns();

            if (this.user.firm_student === 1) {
                this.wyplata_umowa_zlecenie += +this.wyplata_umowa_wynajmu;

            }
            this.componentKey += 1;

        },
        toggleBalance(balance) {
            if (!!_.find(this.selectedBalances, (item) => item.id === balance.id)) {
                _.remove(this.selectedBalances, {id: balance.id});
            } else {
                this.selectedBalances.push(balance);
            }
            if (!this.selectedBalances || this.selectedBalances.length === 0) {
                this.vat2 = 0;
                this.wyplata_zwrot_kosztow = 0;
            }
            this.calculatePayment();
        },
        calculatePayment() {
            this.submitDisabled = this.selectedBalances.length === 0;
            this.provisionFromBalances = 0;
            let total = 0.00;
            let onlinePayment = 0.00;
            let totalVat = 0.00;
            let totalCash = 0.00;

            this.invoices_vat_return = +this.user.invoices_vat_balance;
            this.invoices_cost_return = +this.user.invoices_cost_balance;

            if (+this.firma === 1) {
                this.wyplata_umowa_zlecenie = 0;
                this.wyplata_zwrot_kosztow = 0;
                this.wyplata_umowa_serwisowa = 0;
            }

            for (let balance of this.selectedBalances) {
                this.provisionFromBalances += Math.abs(Math.round(parseFloat(balance.provision) * 100) / 100);
                const partBrutto = parseFloat(balance.total);
                total += partBrutto;
                onlinePayment += parseFloat(balance.online);

                if (balance.imported && balance.imported.length > 0) {
                    for (let i = 0; i < balance.imported.length; i++) {
                        const vatItem = balance.imported[i];
                        totalVat += Math.abs(parseFloat(vatItem.value));
                    }
                } else {
                    const partVat = parseFloat(balance.vat) / 100;
                    totalVat += Math.abs(partBrutto * partVat);
                }
                totalCash += +balance.collected_cash;
            }
            switch (+this.user.provision_type) {
                case 1:
                    this.provisionFromBalances = Math.round(total * (+this.user.provision_value / 100) * 100) / 100;
                    break;
            }
            this.provisionFromBalances = _.round(this.provisionFromBalances * this.provisionMultiplier, 2);
            totalVat = Math.round(totalVat * 100) / 100;

            this.calculated.brutto = Math.round(total * 100) / 100;
            this.calculated.cash = Math.round(totalCash * 100) / 100;
            this.calculated.onlinePayment = Math.round(onlinePayment * 100) / 100;
            this.calculated.totalVat = totalVat;
            if (this.vat2 > this.calculated.totalVat) {
                this.vat2 = this.calculated.totalVat;
            }

            const prowizja = _.round(+this.provision + this.provisionFromBalances, 2);

            this.provisionTotal = prowizja;
            const koszt_zlecenie_umowa = +this.cost_uz_dochodowy;
            const zwrot_vat = +this.vat2;
            const przelew = parseInt(this.elixir);
            const total_online = onlinePayment;

            const przelew_kwota = (przelew === 0) ? 0.00 : 7.00;


            let additional = 0;
            for (let field of this.paymentAdditionalFields) {
                additional += +field.value;
            }
            this.total_payment = Math.round(100 * Math.max(0, total_online - totalVat - prowizja - koszt_zlecenie_umowa - przelew_kwota + zwrot_vat + additional)) / 100;
            this.kwota_z_wyplat = Math.round(100 * Math.max(0, this.total_payment - this.wyplata_umowa_zlecenie - this.wyplata_zwrot_kosztow - this.wyplata_umowa_serwisowa)) / 100;

            this.wyplata_umowa_wynajmu = this.kwota_z_wyplat;
            this.zwrot_vat = zwrot_vat;
        },
        referralCbChanged() {
            if (this.loading) {
                return;
            }
            if (this.user.referral_ask > 0) {
                if (this.referral_cb) {
                    this.wyplata_zwrot_kosztow += +this.user.referral_ask;
                    this.total_payment += +this.user.referral_ask;
                } else {
                    this.wyplata_zwrot_kosztow -= +this.user.referral_ask;
                    this.total_payment -= +this.user.referral_ask;
                }

            }
        }
    },
    beforeMount() {
        this.init();
    }
}
</script>
